import React from 'react';
import { separator } from 'utils/number';
import Text from 'components/Text';
import Col from 'components/Col';
import Row from 'components/Row';
import { Box } from 'grommet';
import { GridList } from 'tutadoo-design-system/src/components';

function DashboardTab({ event: { orders_aggregate, ticket_types, ticket_distribution } }) {
  return (
    <div className="bg-purple-50 flex-grow py-12 px-10">
      <div className="flex justify-between">
        <div>
          <h1 className="text-4xl font-bold text-purple-900 mt-">Overview</h1>
        </div>
        {/* <div>
          <div className="flex items-center border rounded-lg bg-white w-max py-2 px-4 space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
            <input type="text" placeholder="Search" />
          </div>
        </div> */}
      </div>
      <div>
        <div className="flex md:space-x-10 flex-col md:flex-row">
          <div className="flex w-full items-center justify-around p-6 bg-white w-64 rounded-xl space-x-2 mt-10 shadow-lg">
            <div>
              <span className="text-sm font-semibold text-gray-400">Revenue</span>
              <h1 className="text-2xl font-bold">
                KES {separator(orders_aggregate.aggregate.sum?.total)}
              </h1>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 text-purple-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 11l7-7 7 7M5 19l7-7 7 7"
                />
              </svg>
            </div>
          </div>
          <div className="flex w-full items-center justify-around p-6 bg-white w-64 rounded-xl space-x-2 mt-10 shadow-lg">
            <div>
              <span className="text-sm font-semibold text-gray-400">Bookings</span>
              <h1 className="text-2xl font-bold">{orders_aggregate.aggregate.count}</h1>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 text-purple-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
              </svg>
            </div>
          </div>
          <div className="flex w-full items-center justify-around p-6 bg-white w-64 rounded-xl space-x-2 mt-10 shadow-lg">
            <div>
              <span className="text-sm font-semibold text-gray-400">Tickets</span>
              <h1 className="text-2xl font-bold">
                {ticket_distribution.reduce((n, { count }) => n + count, 0)}
              </h1>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 text-purple-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
                />
              </svg>
            </div>
          </div>
        </div>
        {/* <div className="flex mt-10 space-x-10">
          <div className="bg-white w-full p-8 flex items-center justify-around rounded-xl shadow-lg">
            <div className="space-y-2">
              <h3 className="text-sm font-semibold text-gray-400">Daily Revenue Chart</h3>
              <h1 className="text-4xl font-bold text-purple-600">KES0</h1>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-40 w-40 text-purple-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                />
              </svg>
            </div>
          </div>
        </div> */}
        <div />
        <div />
      </div>

      <div>
        <Col className="mt-10 mb-4">
          <Text label="Tickets sold" large bold className="" />
          <GridList>
            {ticket_distribution.length === 0 && <Text label="No tickets have been sold" />}
            {ticket_distribution.map(({ name, count, amount, ticket_id }) => (
              <div className="flex items-center justify-around p-6 bg-white lg:w-64 w-full rounded-xl space-x-2 mt-10 shadow-lg">
                <div>
                  <span className="text-sm font-semibold text-gray-400">
                    {ticket_types.filter((v) => v.id === ticket_id)[0].name}
                  </span>
                  <h1 className="text-l font-bold">KES {separator(amount)}</h1>
                  <span className="text-sm font-bold">{separator(count)} ticket(s)</span>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8 text-purple-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 11l7-7 7 7M5 19l7-7 7 7"
                    />
                  </svg>
                </div>
              </div>
            ))}
          </GridList>
        </Col>
        <div />
        <div />
      </div>
      <div />
    </div>
  );
}

export default DashboardTab;
