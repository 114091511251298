import React from 'react';

export default function TicketIcon({ ticketType, currency, price }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 124 134"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          d="M108.123 92.3134C108.123 86.6489 112.311 82.0464 117.466 82.0464V12.3726C117.466 8.83227 114.889 6 111.667 6H12.2996C9.07762 6 6.5 8.83227 6.5 12.3726V82.0464C11.6552 82.0464 15.8439 86.6489 15.8439 92.3134C15.8439 97.9779 11.6552 102.651 6.5 102.651V121.627C6.5 125.168 9.07762 128 12.2996 128H111.667C114.889 128 117.466 125.168 117.466 121.627V102.651C112.311 102.651 108.123 98.0488 108.123 92.3134Z"
          fill="white"
        />
        <path d="M22.2234 91.5344H18.3569V92.2425H22.2234V91.5344Z" fill="#E0E0E0" />
        <path
          d="M94.5258 92.2427H87.3084V91.5347H94.5258V92.2427ZM80.0911 92.2427H72.8738V91.5347H80.0911V92.2427ZM65.592 92.2427H58.3747V91.5347H65.592V92.2427ZM51.1573 92.2427H43.94V91.5347H51.1573V92.2427ZM36.6582 92.2427H29.4409V91.5347H36.6582V92.2427Z"
          fill="#E0E0E0"
        />
        <path d="M105.674 91.5347H101.808V92.2427H105.674V91.5347Z" fill="#E0E0E0" />
      </g>
      {/* <path
        d="M46.4227 50.008V54.232H39.7507V56.584H44.5507V60.592H39.7507V67H34.4227V50.008H46.4227ZM56.7713 67L53.5553 60.904V67H48.2273V50.008H56.1473C57.5233 50.008 58.6993 50.256 59.6753 50.752C60.6513 51.232 61.3793 51.896 61.8593 52.744C62.3553 53.576 62.6033 54.52 62.6033 55.576C62.6033 56.712 62.2833 57.72 61.6433 58.6C61.0193 59.48 60.1153 60.112 58.9313 60.496L62.6273 67H56.7713ZM53.5553 57.352H55.6673C56.1793 57.352 56.5633 57.24 56.8193 57.016C57.0753 56.776 57.2033 56.408 57.2033 55.912C57.2033 55.464 57.0673 55.112 56.7953 54.856C56.5393 54.6 56.1633 54.472 55.6673 54.472H53.5553V57.352ZM69.6335 54.232V56.344H74.9135V60.352H69.6335V62.752H75.6335V67H64.3055V50.008H75.6335V54.232H69.6335ZM82.9694 54.232V56.344H88.2494V60.352H82.9694V62.752H88.9694V67H77.6414V50.008H88.9694V54.232H82.9694Z"
        fill="#2F1160"
      /> */}
      <text
        x="35"
        y="60"
        fontSize="1.5em"
        fill="#2F1160"
        fontWeight="bold"
        fontVariant="all-small-caps"
      >
        {ticketType || 'FREE'}
      </text>
      {price ? (
        <text
          x="20"
          y="115"
          width="100%"
          tw="text-center"
          fontSize="1em"
          fill="#2F1160"
          fontWeight="bold"
          fontVariant="all-small-caps"
        >
          {currency} {price}
        </text>
      ) : (
        <line x1="33" y1="110" x2="90" y2="110" stroke="#2F1160" strokeWidth="10" />
      )}
      <defs>
        <filter
          id="filter0_d"
          x="0.5"
          saf
          y="0"
          width="122.966"
          height="134"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
