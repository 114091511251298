import React from 'react';
import { Router } from '@reach/router';
import EventDashboard from 'components/EventDashboard';
import Layout from 'components/Layout';
import tw from 'twin.macro';
import EventCardAnimation from 'components/EventCardAnimation';

const Wrapper = tw.div`w-full lg:w-4/6 mx-auto`;

const NotFound = () => <EventCardAnimation />;

function Dashboard() {
  return (
    <Layout noPadding>
      <Wrapper>
        <Router basepath="/host/event/dashboard">
          <EventDashboard path=":id" />
          <EventDashboard path=":id/:tab" />
          <NotFound default />
        </Router>
      </Wrapper>
    </Layout>
  );
}

export default Dashboard;
