import React from 'react';

import { Box, Text, DataTable, Heading } from 'grommet';
import { nameify } from 'utils/string';
import { CSVLink } from 'react-csv';
import slugify from 'utils/slugify';
import styled from 'styled-components';
import tw from 'twin.macro';

const ramda = require('ramda');

const TableHeader = styled.span(() => [tw`truncate hover:max-w-full max-w-xs`]);
const TableCell = styled.span(() => [tw`min-w-[250px]`]);

interface AttendeeListProps {
  event?: namespace.Event;
}
function AttendeeList({ event: { name, attendees, address, ticket_types } }: AttendeeListProps) {
  const extraHeaders = [];
  const columns = [
    { property: 'order_id', header: <TableHeader>Booking ID</TableHeader> },
    {
      property: 'booking_time',
      header: <TableHeader>Booking Time</TableHeader>
    },
    {
      property: 'selected_day',
      header: <TableHeader>Day</TableHeader>
    },
    {
      property: 'name',
      header: <TableHeader>Ticket Name</TableHeader>
    },
    {
      property: 'first_name',
      header: <TableHeader>First Name</TableHeader>,
      render: (datum) => nameify(datum.first_name)
    },
    {
      property: 'last_name',
      header: <TableHeader>Last Name</TableHeader>,
      render: (datum) => nameify(datum.last_name)
    },
    { property: 'email', header: <TableHeader>Email</TableHeader> },
    { property: 'phone', header: <TableHeader>Phone</TableHeader> }
  ];

  if (attendees.length > 0) {
    const lastAnswers = attendees[attendees.length - 1]?.answers;
    if (lastAnswers) {
      Object.keys(lastAnswers).forEach((key) => {
        columns.push({
          property: key,
          header: <TableHeader>{key}</TableHeader>,
          render: (datum) => {
            try {
              return datum.answers[key];
            } catch (error) {
              return '';
            }
          }
        });
        extraHeaders.push(key);
      });
    }
  }
  if (ticket_types.length > 0) {
    ticket_types.forEach((tt) => {
      if (tt.questions && tt.questions.length > 0) {
        tt.questions.forEach((q) => {
          const key = q.name || q;
          columns.push({
            property: key,
            header: <TableHeader>{key}</TableHeader>,
            render: (datum) => {
              return datum.answers[key];
            }
          });
          extraHeaders.push(key);
        });
      }
    });
  }
  columns.push({
    property: 'venue',
    header: <TableHeader>Venue</TableHeader>,
    render: (datum) => {
      return <TableCell>{datum.venue?.address || address}</TableCell>;
    }
  });

  const [select, setSelect] = React.useState([]);
  const [sort, setSort] = React.useState({
    property: 'id',
    direction: 'desc'
  });
  if (!attendees) return null;
  if (attendees.length === 0)
    return (
      <Box pad="large" justify="center" align="center">
        <Text>No tickets have been sold</Text>
      </Box>
    );

  return (
    <Box>
      <Box
        pad={{ horizontal: 'large', vertical: 'medium' }}
        direction="row"
        align="center"
        justify="between"
      >
        <Heading level="3" color="purple-800">
          Tickets
        </Heading>
        {attendees && (
          <Box
            direction="row"
            background="purple-800"
            round="medium"
            pad="xsmall"
            width="150px"
            justify="center"
            alignSelf="end"
          >
            <CSVLink
              data={attendees.map((attendee) =>
                ramda.pick(
                  [
                    'id',
                    'booking_time',
                    'selected_day',
                    'name',
                    'first_name',
                    'last_name',
                    'email',
                    'phone',
                    ...extraHeaders,
                    'venue',
                    'tickets',
                    'currency',
                    'total'
                  ],
                  { ...attendee, ...(attendee.answers || {}) }
                )
              )}
              filename={`tutadoo_attendees_${slugify(name)}.csv`}
            >
              Download
            </CSVLink>
          </Box>
        )}
      </Box>
      <Box background="dark-grey-100" height="1px" fill="horizontal" />
      <Box pad={{ vertical: 'small', horizontal: 'medium' }}>
        <DataTable
          columns={columns.map((c) => ({
            ...c,
            search:
              c.property.includes('id') ||
              c.property.includes('name') ||
              c.property === 'email' ||
              c.property === 'phone'
            //   c.property === 'venue'
          }))}
          data={attendees}
          step={20}
          sort={sort}
          onSort={setSort}
          resizeable
          paginate
          //   onSelect={setSelect}
          select={select}
        />
      </Box>
    </Box>
  );
}

AttendeeList.defaultProps = {};

export default AttendeeList;
