import React from 'react';
import tw from 'twin.macro';
import Text from 'components/Text';
import Col from 'components/Col';
import styled from 'styled-components';

const Label = styled(Text)`
  ${tw`block leading-normal cursor-pointer`}
  ${tw`p-0 m-0`}
  & + label {
    ${tw`p-0 m-0`}
  }
`;

const Section = styled.div`
  //   ${tw`w-full`}
  .tab-content {
    // max-height: 0;
    -webkit-transition: max-height 0.35s;
    -o-transition: max-height 0.35s;
    transition: max-height 0.35s;
  }
  ${Label} {
    ${tw`pl-0`}
  }
  input {
    ${tw`absolute h-14 opacity-0`}
  }
  //   input + div {
  //     ${tw`transition-transform float-right relative right-0`}
  //     ${tw`my-4`}
  //   }
  //   input:checked + div {
  //     transform: rotate(180deg);
  //   }
  //   input:checked ~ .tab-content {
  //     max-height: 100vh;
  //   }
`;

const AccordionList = ({ renderItem, items }) => {
  return (
    <Section>
      {items.map((item, i) => (
        <div key={item.id}>
          <div className="tab h-full">
            <input id={`tab-${item.name}`} type="radio" name="tabs" checked />
            {/* <div className="rounded-full border border-grey w-7 h-7 flex items-center justify-center">
              <svg
                aria-hidden="true"
                data-reactid={266}
                fill="none"
                height={24}
                stroke="#606F7B"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                viewBox="0 0 24 24"
                width={24}
                xmlns="http://www.w3.org/2000/svg"
              >
                <polyline points="6 9 12 15 18 9" />
              </svg>
            </div> */}
            <Col spacing="sm">
              <Label label={item.name} medium bolder />
              {item.description && <Label label={item.description} small />}
            </Col>
            <div className="tab-content">{item.ticket_types.map(renderItem)}</div>
          </div>
        </div>
      ))}
    </Section>
  );
};

AccordionList.defaultProps = {
  items: [{ id: '1' }, { id: 2 }, { id: 3 }]
};

export default AccordionList;
